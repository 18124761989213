import $ from 'jquery';
import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import VuexORM from '@vuex-orm/core';
import VuexORMAxios from '@vuex-orm/plugin-axios';
import * as models from '../models';

const Authorization = localStorage.getItem("authToken") ? `Bearer ${localStorage.getItem("authToken")}` : null;

Vue.use(Vuex);

export const vuexOrmPlugin = {
    register(axiosConfig = {}) {
        const database = new VuexORM.Database();

        if (Authorization) {
            axiosConfig.headers.Authorization = Authorization;
            axiosConfig.headers["Access-Control-Allow-Origin"] = "*";
        }

        VuexORM.use(VuexORMAxios, {
            axios: axios.create({
                ...axiosConfig,
                paramsSerializer: $.param
            }),
            dataKey: "data"
        });

        for (const key in models) {
            if (models[key]) {
                const model = models[key];

                database.register(model);
            }
        }

        return VuexORM.install(database);
    }
}

export const plugins = [
    vuexOrmPlugin.register({
        headers: {
            'X-Requested-With': 'XMLHttpRequest'
        },
        baseURL: process.env.VUE_APP_BACKEND_URL || 'http://localhost:40005'
            // baseURL: 'https://service.xn--l3cgao1bcb5b5d8hf8b6f.com/'
    })
];

const store = new Vuex.Store({
    state: {
        sidebarCollapsed: false,
        sidebarCollapsedMobile: false
    },

    plugins,

    mutations: {
        SET_SIDEBAR_COLLAPSED(state, value) {
            state.sidebarCollapsed = value;
        },
        SET_SIDEBAR_COLLAPSED_MOBILE(state, value) {
            state.sidebarCollapsedMobile = value;
        }
    }
});

export default store;