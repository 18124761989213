import Vue from "vue";
import Router from "vue-router";
import NProgress from "nprogress";
import { Auth } from "../models/Auth";

Vue.use(Router);

const router = new Router({
  mode: "history",

  base: __dirname,

  scrollBehavior(to) {
    if (to.hash) {
      return { selector: to.hash, behavior: "smooth" };
    }

    return { x: 0, y: 0, behavior: "smooth" };
  },

  routes: [
    {
      path: "/survey-question",
      name: "SurveyQuestion",
      meta: {
        auth: false,
        isPublic: true,
        layout: "background-no",
      },
      component: () => import( /* webpackChunkName: "survey--index" */ "../pages/survey-question/index"),
    },

    {
      path: "/tutorials",
      name: "Tutorial",
      meta: {
        auth: false,
        isPublic: true,
        layout: "no",
      },
      component: () => import( /* webpackChunkName: "tutorial--index" */ "../pages/tutorial/index"),
    },

    {
      path: "/registers",
      name: "Register",
      meta: {
        auth: false,
        isPublic: true,
        layout: "no",
      },
      component: () => import( /* webpackChunkName: "register--index" */ "../pages/register/index"),
    },

    {
      path: "/registers/detail",
      name: "Detail",
      meta: {
        auth: false,
        isPublic: true,
        layout: "no",
      },
      component: () => import( /* webpackChunkName: "register--detail" */ "../pages/register/detail"),
    },

    {
      path: "/sign-in",
      name: "SignIn",
      meta: {
        auth: false,
        layout: "no"
      },
      component: () => import( /* webpackChunkName: "auth--signin" */ "../pages/authentication/sign-in-v2"),
    },

    {
      path: "/maintenance",
      name: "Maintenance",
      meta: {
        layout: "no"
      },
      component: () => import( /* webpackChunkName: "maintenance" */ "../pages/maintenance"),
    },

    {
      path: "/",
      name: "Home",
      meta: {
        auth: true
      },
      component: () => import( /* webpackChunkName: "home" */ "../pages/home"),
    },

    {
      path: "/lesson-plans",
      name: "LessonPlan",
      meta: {
        auth: true
      },
      component: () => import( /* webpackChunkName: "lesson-plan--index" */ "../pages/lesson-plan/index"),
    },

    {
      path: "/classes",
      name: "Class",
      meta: {
        auth: true
      },
      component: () => import( /* webpackChunkName: "class--index" */ "../pages/class/index"),
    },

    {
      path: "/transactions",
      name: "Transection",
      meta: {
        auth: true
      },
      component: () => import( /* webpackChunkName: "transaction--index" */ "../pages/transection/index"),
    },

    {
      path: "/certificate",
      name: "Certificate",
      meta: {
        auth: true
      },
      component: () => import( /* webpackChunkName: "certificate--index" */ "../pages/certificate/index"),
    },

    {
      path: "/lessons",
      name: "Lesson",
      meta: {
        auth: true
      },
      component: () => import( /* webpackChunkName: "lesson--index" */ "../pages/lesson/index"),
    },

    {
      path: "/dashboard",
      name: "Dashboard",
      meta: {
        auth: true,
        admin: true,
        // superAdmin: true
      },
      component: () => import( /* webpackChunkName: "dashboard" */ "../pages/dashboard"),
    },

    // {
    //   path: "/master-data/banner-news",
    //   name: "MasterBannerNews",
    //   meta: {
    //     auth: true,
    //     // admin: true,
    //     superAdmin: true
    //   },
    //   component: () => import( /* webpackChunkName: "master-data--banner-news" */ "../pages/master-data/banner-news"),
    // },

    // {
    //   path: "/master-data/headquarters",
    //   name: "MasterHeadquarter",
    //   meta: {
    //     auth: true,
    //     // admin: true,
    //     superAdmin: true
    //   },
    //   component: () => import( /* webpackChunkName: "master-data--headquarter" */ "../pages/master-data/headquarter"),
    // },

    // {
    //   path: "/master-data/divisions",
    //   name: "MasterDivision",
    //   meta: {
    //     auth: true,
    //     // admin: true,
    //     superAdmin: true
    //   },
    //   component: () => import( /* webpackChunkName: "master-data--division" */ "../pages/master-data/division"),
    // },

    // {
    //   path: "/master-data/stations",
    //   name: "MasterStation",
    //   meta: {
    //     auth: true,
    //     // admin: true,
    //     superAdmin: true
    //   },
    //   component: () => import( /* webpackChunkName: "master-data--station" */ "../pages/master-data/station"),
    // },

    {
      path: "/master-data/schools",
      name: "MasterSchool",
      meta: {
        auth: true,
        admin: true
      },
      component: () => import( /* webpackChunkName: "master-data--school" */ "../pages/master-data/school"),
    },

    {
      path: "/master-data/administrators",
      name: "MasterAdministrator",
      meta: {
        auth: true,
        admin: true,
        // superAdmin: true
      },
      component: () => import( /* webpackChunkName: "master-data--administrator" */ "../pages/master-data/administrator"),
    },

    {
      path: "/master-data/teachers",
      name: "MasterDataTeacher",
      meta: {
        auth: true,
        admin: true
      },
      component: () => import( /* webpackChunkName: "master-data--teacher" */ "../pages/master-data/teacher"),
    },

    {
      path: "/master-data/students",
      name: "MasterDataStudent",
      meta: {
        auth: true,
        admin: true
      },
      component: () => import( /* webpackChunkName: "master-data--student" */ "../pages/master-data/student"),
    },

    {
      path: "/master-data/student-old",
      name: "MasterDataStudentOld",
      meta: {
        auth: true,
        admin: true
      },
      component: () => import( /* webpackChunkName: "master-data--student-old" */ "../pages/master-data/student-old"),
    },

    {
      path: "/master-data/surveys",
      name: "MasterDataSurvey",
      meta: {
        auth: true,
        admin: true,
        superAdmin: true
      },
      component: () => import( /* webpackChunkName: "master-data--survey" */ "../pages/master-data/survey"),
    },

    {
      path: "/master-data/registrations",
      name: "MasterDataRegistration",
      meta: {
        auth: true,
        admin: true,
        superAdmin: true
      },
      component: () => import( /* webpackChunkName: "master-data--registration" */ "../pages/master-data/registration"),
    },

    {
      path: "/master-data/lessons",
      name: "MasterDataLesson",
      meta: {
        auth: true,
        admin: true,
        superAdmin: true
      },
      component: () => import( /* webpackChunkName: "master-data--lesson" */ "../pages/master-data/lesson"),
    },

    {
      path: "/master-data/tutorials",
      name: "MasterDataTutorial",
      meta: {
        auth: true,
        admin: true,
        superAdmin: true
      },
      component: () => import( /* webpackChunkName: "master-data--tutorial" */ "../pages/master-data/tutorial"),
    },

    {
      path: "/report/transactions",
      name: "ReportTransaction",
      meta: {
        auth: true,
        admin: true,
        // superAdmin: true
      },
      component: () => import( /* webpackChunkName: "report--transaction" */ "../pages/report/transaction"),
    },

    {
      path: "/report/progress",
      name: "ReportProgress",
      meta: {
        auth: true,
        admin: true,
        // superAdmin: true
      },
      component: () => import( /* webpackChunkName: "report--progress" */ "../pages/report/progress"),
    },

    {
      path: "/report/overview",
      name: "ReportOverview",
      meta: {
        auth: true,
        admin: true,
        // superAdmin: true
      },
      component: () => import( /* webpackChunkName: "report--overview" */ "../pages/report/overview"),
    },

    {
      path: "/report/survey/:surveyId",
      name: "ReportSurvey",
      meta: {
        auth: true,
        admin: true,
        // superAdmin: true
      },
      component: () => import( /* webpackChunkName: "report--survey" */ "../pages/report/survey"),
    },

    {
      path: "/report/certificate",
      name: "ReportCertificate",
      meta: {
        auth: true,
        admin: true,
        // superAdmin: true
      },
      component: () => import( /* webpackChunkName: "report--certificate" */ "../pages/report/certificate"),
    },

    {
      path: "/report/survey-teacher-dare",
      name: "SurveyTeacherDare",
      meta: {
        auth: true,
        admin: true,
        // superAdmin: true
      },
      component: () => import( /* webpackChunkName: "report--survey-teacher-dare" */ "../pages/report/survey-teacher-dare"),
    },

    {
      path: "/report/survey-student",
      name: "SurveyStudent",
      meta: {
        auth: true,
        admin: true,
        // superAdmin: true
      },
      component: () => import( /* webpackChunkName: "report--survey-student" */ "../pages/report/survey-student"),
    },

    {
      path: "/report/survey-teacher",
      name: "SurveyTeacher",
      meta: {
        auth: true,
        admin: true,
        // superAdmin: true
      },
      component: () => import( /* webpackChunkName: "report--survey-teacher" */ "../pages/report/survey-teacher"),
    },

    {
      path: "/report/survey-parent",
      name: "SurveyParent",
      meta: {
        auth: true,
        admin: true,
        // superAdmin: true
      },
      component: () => import( /* webpackChunkName: "report--survey-parent" */ "../pages/report/survey-parent"),
    },

    {
      path: "/setting/announcement",
      name: "SettingAnnouncement",
      meta: {
        auth: true,
        admin: true,
        superAdmin: true
      },
      component: () => import( /* webpackChunkName: "report--survey-parent" */ "../pages/setting/announcement"),
    },

    {
      path: "/setting/config-class",
      name: "SettingConfigUploadClass",
      meta: {
        auth: true,
        // admin: true,
        superAdmin: true
      },
      component: () => import( /* webpackChunkName: "report--survey-parent" */ "../pages/setting/configClass"),
    },

    {
      path: "/setting/banner-news",
      name: "MasterBannerNews",
      meta: {
        auth: true,
        // admin: true,
        superAdmin: true
      },
      component: () => import( /* webpackChunkName: "master-data--banner-news" */ "../pages/master-data/banner-news"),
    },

    {
      path: "/setting/headquarters",
      name: "MasterHeadquarter",
      meta: {
        auth: true,
        // admin: true,
        superAdmin: true
      },
      component: () => import( /* webpackChunkName: "master-data--headquarter" */ "../pages/master-data/headquarter"),
    },

    {
      path: "/setting/divisions",
      name: "MasterDivision",
      meta: {
        auth: true,
        // admin: true,
        superAdmin: true
      },
      component: () => import( /* webpackChunkName: "master-data--division" */ "../pages/master-data/division"),
    },

    {
      path: "/setting/stations",
      name: "MasterStation",
      meta: {
        auth: true,
        // admin: true,
        superAdmin: true
      },
      component: () => import( /* webpackChunkName: "master-data--station" */ "../pages/master-data/station"),
    },

    {
      path: "/setting/config-m-class",
      name: "SettingConfigMClass",
      meta: {
        auth: true,
        // admin: true,
        superAdmin: true
      },
      component: () => import( /* webpackChunkName: "master-data--banner-news" */ "../pages/setting/configMClass"),
    },

    {
      path: "/setting/config-quota",
      name: "SettingConfigQuota",
      meta: {
        auth: true,
        admin: true,
        superAdmin: true
      },
      component: () => import( /* webpackChunkName: "master-data--banner-news" */ "../pages/setting/configQuota"),
    },

    // otherwise redirect to home
    { path: "*", name: "home", redirect: "/" }
  ],
});

router.beforeEach(async (to, from, next) => {
  NProgress.start();
  NProgress.set(0.1);

  const { auth, admin, superAdmin, isPublic = false } = to.meta;

  const authToken = Auth.getAuthToken();

  if (auth === true && !authToken) {
    return next({ path: "/sign-in", query: { redirectUrl: to.path } });
    // if (!Vue.$cookies.isKey("user")) {
    //   return next({ path: "/login", query: { returnUrl: to.path } });
    // }

    // // check if route is restricted by role
    // if (authorize.length) {
    //   const userInfo = Vue.$cookies.get("user");
    //   const { roles } = userInfo;

    //   const checkIsUserAuthorize = roles.some((role) => {
    //     return authorize.includes(role);
    //   });

    //   if (!checkIsUserAuthorize) {
    //     // role not authorised so redirect to LU page
    //     next({ path: "/" });
    //   }
    // }
  }

  if (admin === true || superAdmin === true) {
    let valid = false;

    await Auth.load();

    const authUser = Auth.user();

    // console.log(admin, authUser.isAdminType);
    if (admin === true) {
      if (!authUser.isAdminType) {
        return next({ path: "/" });
      } else {
        valid = true;
      }
    }

    // console.log(superAdmin, authUser.isSuperAdmin);
    if (!valid && superAdmin === true) {
      if (!authUser.isSuperAdmin) {
        return next({ path: "/" });
      }
    }
  }

  if (auth === false && authToken && !isPublic) {
    return next({ path: "/" });
  }

  next();
});

router.afterEach(() => {
  setTimeout(() => NProgress.done(), 500);
});

export default router;
