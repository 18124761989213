import { Model } from "@vuex-orm/core";

export class GuidelineWithdraw extends Model {
	static get entity() {
		return "guideline_withdraw";
	}

	static fields() {
		return {
      id: this.attr(null),
			guideline_withdraw_title: this.attr(null),
			key_img: this.attr(null),
      create_by: this.attr(null),
      update_by: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
		};
	}

	static get apiConfig() {
		return {
			actions: {
				findAll(params = {}) {
					return this.get("/guideline_withdraw", { params });
				},

        create(body) {
          return this.post("/guideline_withdraw", body);
        },

				update(id, body) {
					return this.patch(`/guideline_withdraw/${id}`, body);
				},

				destroy(id) {
						return this.delete(`/guideline_withdraw/${id}`, { delete: id });
				}
			},
		};
	}
}
