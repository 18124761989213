import { Model } from "@vuex-orm/core";

export class MHeadquarter extends Model {
    static get entity() {
        return "m_headquarters"
    }

    static fields() {
        return {
            "id": this.attr(null),
            "m_headquarters_name": this.string(""),
            "create_by": this.attr(null),
            "update_by": this.attr(null),
            "createdAt": this.attr(null),
            "updatedAt": this.attr(null)
        }
    }

    static get apiConfig() {
        return {
            actions: {
                findAll(params = {}) {
                    return this.get('/m_headquarters', { params });
                },

                create(body) {
                    return this.post('/m_headquarters', body);
                },

                update(id, body) {
                    return this.put(`/m_headquarters/${id}`, body);
                },

                destroy(id) {
                    return this.delete(`/m_headquarters/${id}`, { delete: id });
                }
            }
        };
    }
}