<template>
  <div class="d-flex flex-row flex-wrap">
    <div class="col-lg-3 col-12 px-2 mb-2">
      <label class="font-weight-bold" for="user_countHourLesson"
        >จำนวนชั่วโมงการสอน (ชั่วโมง)</label
      >
      <b-form-input
        id="user_countHourLesson"
        type="number"
        :disabled="!authUser.isAdminType"
        v-model.number="totalTime"
      ></b-form-input>
    </div>
    <div class="col-lg-2 col-12 px-2 mb-2">
      <label class="font-weight-bold" for="user_countYearLesson"
        >จำนวนปีที่สอน (ปี)</label
      >
      <b-form-input
        id="user_countYearLesson"
        type="text"
        :disabled="true"
        v-model="totalYear"
      ></b-form-input>
    </div>
    <div class="col-lg-2 col-12 px-2 mb-2">
      <label class="font-weight-bold" for="ีuser_yearStart"
        >ปีที่เริ่มสอน พ.ศ.</label
      >
      <b-form-input
        id="user_yearStart"
        type="text"
        :disabled="!authUser.isAdminType"
        v-model="startYear"
      ></b-form-input>
    </div>
    <div class="col-lg-3 col-12 px-2 mb-2">
      <label class="font-weight-bold" for="medal"
        >เข็มเชิดชูเกียรติ (ระดับ)</label
      >
      <b-form-input
        id="medal"
        type="text"
        :disabled="true"
        v-model="medal"
      ></b-form-input>
    </div>
    <div class="col-lg-2 col-12 px-2 mb-2">
      <label class="font-weight-bold" for="modelNo">รุ่นครูตำรวจแดร์</label>
      <b-form-input
        id="modelNo"
        type="text"
        :disabled="!authUser.isAdminType"
        v-model="modelNo"
      ></b-form-input>
    </div>
  </div>
</template>

<script>
import { Auth } from "../../models";
// import { map } from "lodash";
export default {
  props: {
    formData: Object,

    userData: {
      type: Object,
      default: () => {},
    },
  },

  model: {
    prop: "formData",
    event: "change",
  },

  data() {
    return {
      totalTime: "",
      totalYear: "0",
      startYear: "-",
      modelNo: "-",
      medal: "",
      // note: "",
    };
  },

  watch: {
    userData: {
      immediate: true,
      handler: "setData",
    },

    totalTime: {
      handler(total_time) {
        this.$emit("change", {
          ...this.formData,
          total_time,
        });
      },
    },

    startYear: {
      handler(start_year) {
        this.$emit("change", {
          ...this.formData,
          start_year,
        });
      },
    },

    modelNo: {
      handler(model_no) {
        this.$emit("change", {
          ...this.formData,
          model_no,
        });
      },
    },
  },

  computed: {
    authUser() {
      return Auth.user();
    },
  },

  methods: {
    setData(user) {
      let {
        total_time = null,
        start_year = null,
        model_no = null,
        medal = null,
      } = user;

      total_time = total_time ? Math.floor(total_time) : 0;

      medal = medal || "ระดับต้น";

      if (total_time >= 200) {
        medal = "ระดับทอง";
      } else if (total_time >= 150) {
        medal = "ระดับเงิน";
      } else if (total_time >= 100) {
        medal = "ระดับทองแดง";
      }

      this.totalTime = total_time;

      this.medal = medal;

      if (start_year && start_year !== "-") {
        const currentYear = new Date().getFullYear() + 543;

        this.startYear = start_year;

        this.totalYear = `${currentYear - parseInt(start_year)}`;
      }

      if (model_no) {
        this.modelNo = model_no;
      }

      this.$emit("change", {
        ...this.formData,
        total_time,
        start_year,
        model_no,
      });

      // if (teacher_dares.length > 0) {
      // this.note = `${teacher_dares.length} ห้อง`;

      // const fiscal_year = map(teacher_dares, "fiscal_year");

      // if (fiscal_year) {
      // const uniqueYears = [...new Set(fiscal_year)].sort();
      // this.totalYear = uniqueYears.length;
      // this.startYear = uniqueYears[0];
      // }
      // }
    },
  },
};
</script>
