<template>
  <div class="app-wrapper bg-white h-100">
    <div class="app-main">
      <transition name="fade" mode="out-in" appear>
        <Header :show-sidebar="false" show-auth />
      </transition>

      <div class="app-content p-0">
        <div
          class="app-content--inner d-flex align-items-center justify-content-center"
        >
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../../components/layout/Header";

export default {
  components: {
    Header,
  },
};
</script>
