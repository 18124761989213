import { Model } from "@vuex-orm/core";

export class Transection extends Model {
    static get entity() {
        return "transections"
    }

    static fields() {
        return {
            "id": this.attr(null),
            "number": this.string(""),
            "date": this.attr(null),
            "total_time": this.attr(null),
            "total_money": this.attr(null),
            "update_by": this.attr(null),
            "createdAt": this.attr(null),
            "updatedAt": this.attr(null)
        }
    }

    static get apiConfig() {
        return {
            actions: {
                findAll(params = {}, props = {}) {
                    return this.get('/transaction', { params }, props);
                },

                findByuserId(id, params = {}) {
                    return this.get(`/transaction/?userProfileId=${id}`, { params });
                },

                create(body = {}) {
                    return this.post('/transaction', body);
                },

                updateTotal(id) {
                    return this.put(`/transaction/moneyAndTime/${id}`);
                },

                deleteTeachingDare(id) {
                    return this.put(`/transaction/teaching_dare/${id}`);
                }
            }
        };
    }
}