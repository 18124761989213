import { Model } from "@vuex-orm/core";

export class Report extends Model {
    static get entity() {
        return "reports"
    }

    static fields() {
        return {}
    }

    static get apiConfig() {
        return {
            actions: {
                getTeachingProgress(params = {}) {
                    return this.get('/report/teaching-progress', { params });
                },
                getTeachingOverview(params = {}) {
                    return this.get('/report/teaching-overview', { params });
                },
                getSurveySummary(params = {}) {
                    return this.get('/report/survey-summary', { params });
                },
                exportTeachingOverview(params = {}, props = {}) {
                    return this.get('/report/teaching-overview/export', { params }, props);
                },
                getUserOverviewSummary(params = {}) {
                    return this.get('/report/teaching-overview-user-summary', { params });
                },
            }
        };
    }
}