import { Model } from "@vuex-orm/core";
import { TeachingPlan } from "./TeachingPlan"

export class TeachingDare extends Model {
    static get entity() {
        return "teaching_dares"
    }

    static fields() {
        return {
            "id": this.attr(null),
            "room": this.string(""),
            "term": this.string(""),
            "year": this.string(""),
            "fiscal_year": this.string(""),
            "plan_student": this.number(0),
            "actual_student": this.attr(null),
            "remark": this.attr(null),
            "create_by": this.attr(null),
            "update_by": this.attr(null),
            "createdAt": this.attr(null),
            "updatedAt": this.attr(null),
            "userProfileId": this.attr(null),
            "mStationId": this.attr(null),
            "mSchoolId": this.attr(null),
            "mClassId": this.attr(null),
            "m_school": this.attr(null),
            "isPlan": this.attr(null), // ["ไม่ทำแผนการสอน", "ทำแผนการสอน"]
            "isPayment": this.boolean(false),
            "teaching_plan_count": this.number(0),
            "teaching_plans": this.hasManyBy(TeachingPlan, 'teachingDareId'),
            "teaching_actual_imgs": this.attr(null)
        }
    }

    static get apiConfig() {
        return {
            actions: {
                findAll(params = {}, props = {}) {
                    return this.get('/teaching_dare', { params }, props);
                },

                findOne(id, params = {}, props = {}) {
                    return this.get(`/teaching_dare/${id}`, { params }, props);
                },

                getDashboard(params = {}) {
                    return this.get(`/teaching_dare/dashboard`, { params }, { save: false });
                },

                getUploadUrl(params = {}) {
                    return this.get(`/teaching_dare/getURL`, { params }, { save: false });
                },

                getImgUrl(params = {}) {
                    return this.get(`/teaching_dare/getImg`, { params }, { save: false });
                },

                getImgBinary(params = {}) {
                    return this.get(`/teaching_dare/getImageBinary`, { params }, { save: false });
                },

                getReportPayment(params = {}, props = {}) {
                    return this.get('/teaching_dare/sumPayment', { params }, props);
                },

                find(id) {
                    return this.get(`/teaching_dare/plan/${id}`);
                },

                createPlan(body) {
                    return this.post(`/teaching_dare/plan/`, body);
                },

                updatePlan(id, body) {
                    return this.put(`/teaching_dare/plan/${id}`, body);
                },

                createActual(body) {
                    return this.post(`/teaching_dare/actual/`, body);
                },

                deletePlan(id) {
                    return this.delete(`/teaching_dare/${id}`);
                },

                getIssueCertificate(params = {}) {
                    return this.get(`/teaching_dare/reportIssueCertificated`, { params }, { save: false });
                },

                updateIssueCertificate(id, body) {
                    return this.put(`/teaching_dare/issueCertificate/${id}`, body);
                },
            }
        };
    }
}