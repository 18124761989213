import { Model } from "@vuex-orm/core";

export class ConfigTeachingDareLimit extends Model {
  static get entity() {
    return "config_teaching_dare_limit";
  }

  static fields() {
    return {
      id: this.attr(null),
      limit: this.number(0),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
    };
  }

  static get apiConfig() {
    return {
      actions: {
        findAll(params = {}) {
          return this.get("config_teaching_dare_limit", { params });
        },

        update(body) {
          return this.put(`/config_teaching_dare_limit`, body);
        },
      },
    };
  }
}
