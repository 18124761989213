import { Model } from "@vuex-orm/core";

export class TransectionType extends Model {
    static get entity() {
        return "transection_type"
    }

    static fields() {
        return {
            "id": this.attr(null),
            "m_transaction_name": this.string(""),
            "m_transaction_rate": this.attr(null),
            "active_flag": this.attr(null),
            "create_by": this.attr(null),
            "update_by": this.attr(null),
            "createdAt": this.attr(null),
            "updatedAt": this.attr(null)
        }
    }

    static get apiConfig() {
        return {
            actions: {
                findAll(params = {}) {
                    return this.get('/m_transaction_type', { params });
                },
            }
        };
    }
}