export const RETIRE_CUT_MONTH = 9; // October

export function toBuddhistCalendar(datepicker) {
    // datepicker.components.PickerDay.computed.days = function days() {
    //     const d = this.pageDate
    //     let days = []
    //     // set up a new date object to the beginning of the current 'page'
    //     let dObj = this.useUtc
    //         ? new Date(Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), 1))
    //         : new Date(d.getFullYear(), d.getMonth(), 1, d.getHours(), d.getMinutes())

    //     // Set year to buddhist year
    //     this.utils.setFullYear(dObj, this.utils.getFullYear(dObj) + 543);

    //     let daysInMonth = this.utils.daysInMonth(this.utils.getFullYear(dObj), this.utils.getMonth(dObj))

    //     for (let i = 0; i < daysInMonth; i++) {
    //         days.push({
    //             date: this.utils.getDate(dObj),
    //             timestamp: dObj.getTime(),
    //             isSelected: this.isSelectedDate(dObj),
    //             isDisabled: this.isDisabledDate(dObj),
    //             isHighlighted: this.isHighlightedDate(dObj),
    //             isHighlightStart: this.isHighlightStart(dObj),
    //             isHighlightEnd: this.isHighlightEnd(dObj),
    //             isToday: this.utils.compareDates(dObj, new Date()),
    //             isWeekend: this.utils.getDay(dObj) === 0 || this.utils.getDay(dObj) === 6,
    //             isSaturday: this.utils.getDay(dObj) === 6,
    //             isSunday: this.utils.getDay(dObj) === 0
    //         })
    //         this.utils.setDate(dObj, this.utils.getDate(dObj) + 1)
    //     }
    //     return days
    // };

    datepicker.components.PickerDay.computed.currYearName = function currYearName() {
        const yearSuffix = this.translation.yearSuffix;
        return `${this.utils.getFullYear(this.pageDate) + 543}${yearSuffix}`;
    };

    // datepicker.components.PickerMonth.computed.months = function months() {
    //     const d = this.pageDate
    //     let months = []
    //     // set up a new date object to the beginning of the current 'page'
    //     let dObj = this.useUtc
    //         ? new Date(Date.UTC(d.getUTCFullYear(), 0, d.getUTCDate()))
    //         : new Date(d.getFullYear(), 0, d.getDate(), d.getHours(), d.getMinutes())

    //     // Set year to buddhist year
    //     this.utils.setFullYear(dObj, this.utils.getFullYear(dObj) + 543);

    //     for (let i = 0; i < 12; i++) {
    //         months.push({
    //             month: this.utils.getMonthName(i, this.translation.months),
    //             timestamp: dObj.getTime(),
    //             isSelected: this.isSelectedMonth(dObj),
    //             isDisabled: this.isDisabledMonth(dObj)
    //         })
    //         this.utils.setMonth(dObj, this.utils.getMonth(dObj) + 1)
    //     }
    //     return months
    // };

    datepicker.components.PickerMonth.computed.pageYearName = function pageYearName() {
        const yearSuffix = this.translation.yearSuffix;

        return `${this.utils.getFullYear(this.pageDate) + 543}${yearSuffix}`;
    };

    datepicker.components.PickerYear.methods.selectYear = function selectYear(
        year
    ) {
        if (year.isDisabled) {
            return false;
        }

        const dObj = new Date(year.timestamp);

        this.utils.setFullYear(dObj, this.utils.getFullYear(dObj) - 543);

        this.$emit("selectYear", {
            ...year,
            year: this.utils.getFullYear(dObj),
            timestamp: dObj.getTime(),
        });
    };

    datepicker.components.PickerYear.computed.getPageDecade = function getPageDecade() {
        const decadeStart =
            Math.floor((this.utils.getFullYear(this.pageDate) + 543) / 10) * 10;
        const decadeEnd = decadeStart + 9;
        const yearSuffix = this.translation.yearSuffix;
        return `${decadeStart} - ${decadeEnd}${yearSuffix}`;
    };

    datepicker.components.PickerYear.computed.years = function years() {
        const d = this.pageDate;

        let years = [];

        // set up a new date object to the beginning of the current 'page'7
        let dObj = this.useUtc
            ? new Date(
                Date.UTC(
                    Math.floor(d.getUTCFullYear() / 10) * 10,
                    d.getUTCMonth(),
                    d.getUTCDate()
                )
            )
            : new Date(
                Math.floor(d.getFullYear() / 10) * 10,
                d.getMonth(),
                d.getDate(),
                d.getHours(),
                d.getMinutes()
            );

        // Set year to buddhist year
        this.utils.setFullYear(dObj, this.utils.getFullYear(dObj) + 543);

        for (let i = 0; i < 10; i++) {
            years.push({
                year: this.utils.getFullYear(dObj),
                timestamp: dObj.getTime(),
                isSelected: this.isSelectedYear(dObj),
                isDisabled: this.isDisabledYear(dObj),
            });

            this.utils.setFullYear(dObj, this.utils.getFullYear(dObj) + 1);
        }

        return years;
    };
}

export function formatBuddhistBirthDate(date) {
    const dObj = new Date(date.getTime());

    let day = dObj.getDate();

    let month = dObj.getMonth() + 1;

    if (day < 10) {
        day = `0${day}`;
    }

    if (month < 10) {
        month = `0${month}`;
    }

    return `${day}/${month}/${dObj.getFullYear() + 543}`;
}

export function calculateRetireYear(birthDate, splitted = false) {
    let retireYear = null;

    if (splitted) {
        var parts = birthDate.split("/");

        birthDate = new Date(parseInt(parts[2], 10),
            parseInt(parts[1], 10) - 1,
            parseInt(parts[0], 10));
    }

    if (birthDate) {
        const dObj = new Date(birthDate);

        const birthDateMonth = dObj.getMonth();
        const birthDateYear = dObj.getFullYear();

        if (!isNaN(birthDateYear)) {
            retireYear = birthDateYear + 60;

            if (birthDateMonth >= RETIRE_CUT_MONTH) {
                retireYear += 1;
            }
        }
    }

    return retireYear;
}