import { Model } from "@vuex-orm/core";

export class Registration extends Model {
    static get entity() {
        return "registrations"
    }

    static fields() {
        return {
            id: this.attr(null),
            phone_number: this.string(""),
            first_name: this.string(""),
            last_name: this.string(""),
            id_card: this.string(""),
            bd_date: this.attr(null),
            age: this.attr(null),
            email: this.attr(null),
            workplace_name: this.attr(null),
            position_name: this.attr(null),
            affiliation_name: this.attr(null),
            address: this.attr(null),
            province: this.attr(null),
            amphur: this.attr(null),
            district: this.attr(null),
            individual_img_url: this.attr(null),
            id_card_img_url: this.attr(null),
            payment_img_url: this.attr(null),
            status: this.string(""), // ["รออนุมัติ", "รอชำระเงิน", "รอการยืนยันการชำระเงิน", "ลงทะเบียนสำเร็จ"]
            approve: this.boolean(false),
            registration_code: this.string(""),
            approveByUser: this.attr(null),
            rejectByUser: this.attr(null),
            reject_cause: this.attr(null),
            confirmByUser: this.attr(null),
            m_rank_id: this.attr(null),
            mRank: this.attr(null),
            user_profile_id: this.attr(null),
            createdAt: this.attr(null),
            updatedAt: this.attr(null),
        }
    }

    static get apiConfig() {
        return {
            actions: {
                findAll(params = {}) {
                    return this.get('/registrations', { params });
                },

                findOne(id, params = {}) {
                    return this.get(`/registrations/${id}`, { params });
                },

                findByCode(code) {
                    return this.get(`/registrations/registration-code/${code}`);
                },

                getUploadUrl(params = {}) {
                    return this.get(`/registrations/upload-url`, { params }, { save: false });
                },

                getDashboard(params = {}) {
                    return this.get(`/registrations/dashboard`, { params }, { save: false });
                },

                create(body) {
                    return this.post('/registrations', body);
                },

                checkPhoneNumber(body) {
                    return this.post(`/registrations/check/phone-number`, body)
                },

                checkIdCard(body) {
                    return this.post(`/registrations/check/id-card`, body)
                },

                update(id, body) {
                    return this.put(`/registrations/${id}`, body);
                },

                updateStatusWaitingForPayment(id, { approve_by }) {
                    return this.put(`/registrations/${id}/status/wait-for-payment`, { approve_by });
                },

                updateStatusWaitingForApprove(id, body) {
                    return this.put(`/registrations/${id}/status/wait-for-approve`, body);
                },

                updateApprove(id, body) {
                    return this.put(`/registrations/${id}/approve`, body);
                },

                updateReject(id, { reject_cause, reject_by } = {}) {
                    return this.put(`/registrations/${id}/reject`, { reject_cause, reject_by });
                },

                destroy(id) {
                    return this.delete(`/registrations/${id}`, { delete: id });
                },
            }
        };
    }
}
