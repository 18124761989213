import { Model } from "@vuex-orm/core";

export class ConfigTeachingDare extends Model {
	static get entity() {
		return "config_teaching_dare";
	}

	static fields() {
		return {
			id: this.attr(null),
			is_open: this.boolean(false),
			createdAt: this.attr(null),
			updatedAt: this.attr(null),
		};
	}

	static get apiConfig() {
		return {
			actions: {
				findAll(params = {}) {
					return this.get("/config_teaching_dare", { params });
				},

				update(body) {
					return this.put(`/config_teaching_dare`, body);
				},
			},
		};
	}
}
