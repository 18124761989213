import { Model } from "@vuex-orm/core";

export class MSemester extends Model {
    static get entity() {
        return "m_semesters"
    }

    static fields() {
        return {
            "id": this.attr(null),
            "fiscal_year": this.attr(null),
            "term": this.attr(null),
            "isActive": this.boolean(false)
        }
    }

    static get apiConfig() {
        return {
            actions: {
                findAll(params = {}) {
                    return this.get('/m_semesters', { params });
                },

                create(body = {}) {
                    return this.post('/m_semesters', body);
                },

                update(id, body) {
                    return this.put(`/m_semesters/${id}`, body);
                },

                findCurentSemester(params = {}) {
                    return this.get('/m_semesters/current-semester', { params });
                },

                updateCurentSemester(id) {
                    return this.put(`/m_semesters/updateCurrentSemester/${id}`);
                },
            }
        };
    }
}