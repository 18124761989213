import { Model } from "@vuex-orm/core";

export class ConfigQuota extends Model {
  static get entity() {
    return "config_quota";
  }

  static fields() {
    return {
      id: this.attr(null),
      quota: this.attr(null),
      used: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
      mStationId: this.attr(null),
      mSemesterId: this.attr(null),
    };
  }

  static get apiConfig() {
    return {
      actions: {
        findAll(params = {}) {
          return this.get("/config_quota", { params });
        },

        create(body) {
          return this.post(`/config_quota`, body);
        },

        update(id, body) {
          return this.patch(`/config_quota/${id}`, body);
        },

        updateAll(params = "", body) {
          return this.patch(`/config_quota${params !== "" ? `?${params}` : ``}`, body);
        },
      },
    };
  }
}
