import { Model } from "@vuex-orm/core";

export class StudentDareOld extends Model {
  static get entity() {
    return "student_dares";
  }

  static fields() {
    return {
      id: this.attr(null),
      student_first_name: this.string(""),
      student_last_name: this.string(""),
      student_id_card: this.string(""),
      year: this.string(""),
      term: this.string(""),
      create_by: this.attr(null),
      update_by: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
      m_headquarters_name: this.attr(null),
      m_division_name: this.attr(null),
      m_station_name: this.attr(null),
      m_school_name: this.attr(null),
    };
  }

  static get apiConfig() {
    return {
      actions: {
        findAll(params = {}) {
          console.log(params);
          return this.get("/student_dare/findAllOldStudent", { params });
        },

        async create(form) {
          let promise;

          promise = await this.post(`/student_dare`, {
            ...form,
          });

          const { response_status, response_description, data } =
            promise.response.data;

          if (response_status === "ERROR") {
            throw response_description;
          }

          const { student_dare } = data;

          StudentDareOld.insert({
            data: { ...student_dare },
          });

          return promise;
        },

        async update(id, form) {
          return this.put(`/student_dare/${id}`, {
            ...form,
          });
        },

        destroy(id) {
          return this.delete(`/student_dare/${id}`, { delete: id });
        },
      },
    };
  }
}
