import { Model } from "@vuex-orm/core";
import { SurveyTopic } from "./SurveyTopic"
import { Question } from "./Question";

export class Survey extends Model {
    static get entity() {
        return "surveys"
    }

    static fields() {
        return {
            "id": this.attr(null),
            "survey_title": this.string(""),
            "survey_type": this.attr(null),
            "description": this.string(""),
            "created_by": this.attr(null),
            "update_by": this.attr(null),
            "createdAt": this.attr(null),
            "updatedAt": this.attr(null),
            "survey_topics": this.hasManyBy(SurveyTopic, 'surveyId'),
            "questions": this.hasManyBy(Question, 'surveyTopicId')
        }
    }

    static get apiConfig() {
        return {
            actions: {
                findAll(params = {}) {
                    return this.get('/survey', { params });
                },

                find(id, params = {}) {
                    return this.get(`/survey/${id}`, { params });
                },

                create(body) {
                    return this.post('/survey/form', body);
                },

                update(id, body) {
                    return this.put(`/survey/form/${id}`, body);
                },

                destroy(id) {
                    return this.delete(`/survey/${id}`, { delete: id });
                },

                createAnswer(body) {
                    return this.post('/survey/answer', body);
                },
                getDashboard(params = {}) {
                    return this.get('/survey/answer/getDashboard', { params });
                },
            }
        };
    }
}