<template>
  <div class="user-box ml-2" >
    <a
      href="javascript:void(0);"
      class="p-0 d-flex align-items-center"
      id="userBoxPopover"
    >
      <div class="d-block p-0 avatar-icon-wrapper">
        <!-- <div v-if="!!user.profile_image && !!user.profile_image.url" class="avatar-icon rounded" style="width: 55px; height: 55px">
          <img
            :src="user.profile_image.url"
            class="rounded"
          />
        </div> -->
        
        <div class="avatar-icon rounded" style="width: 55px; height: 55px">
          
          <img
            v-if="!userMedal"
            src="@/assets/img/medal-start.png"
            class="rounded"
          />
          <img
            v-else-if="userMedal === 'ทองแดง'"
            src="@/assets/img/medal-bronze.png"
            class="rounded"
          />
          <img
            v-else-if="userMedal === 'เงิน'"
            src="@/assets/img/medal-silver.png"
            class="rounded"
          />
          <img
            v-else-if="userMedal === 'ทอง'"
            src="@/assets/img/medal-gold.png"
            class="rounded"
          />
          <!-- <b-avatar variant="primary" :text="name.split('')[0]"></b-avatar> -->
          <!-- <b-avatar variant="primary" :src="medalImageSrc"></b-avatar> -->
        </div>
      </div>
      <div class="user-box__name pl-2">
        <div class="font-weight-bold">
          {{ name }}
        </div>
        <span class="text-black">
          <small>ชั่วโมงการสอน: {{ userTotalTime }} ชั่วโมง</small>
        </span>
        <br>
        <span class="text-black">
          <small>ปีที่เกษียณ: {{user.retire_year}}</small>
        </span>
        <br v-if="checkRetireYear">
        <span class="text-black" v-if="checkRetireYear">
          <small style="color: #f00;">{{checkRetireYear? '*ปีหน้าท่านจะเกษียณ': ''}}</small>
        </span>
        <br>
        <span class="text-black-50">
          <b-button variant="link" class="p-0" @click="showModal = true">
            <small>แก้ไขข้อมูลส่วนตัว</small>
          </b-button>
        </span>
      </div>
    </a>

    <create-or-update-user
      :edit-data="user"
      :user-type="user.user_type"
      :disabled-input="{ isAdmin: true, adminType: true }"
      :force-update="forceUpdateUserData"
      force-update-title="ข้อมูลส่วนตัวไม่ครบถ้วน!"
      force-update-message="กรุณาอัพเดตข้อมูล วัน/เดือน/ปีเกิด และ เลขบัตรประชาชน ของท่านให้เรียบร้อย"
      v-model="showModal"
      @create:success="onCreateSuccess"
    ></create-or-update-user>
    <!-- <b-popover triggers="click blur" placement="bottom" custom-class="popover-custom-wrapper popover-custom-lg popover-secondary" ref="userBoxPopover" target="userBoxPopover">
            <ul class="list-group list-group-flush text-left bg-transparent">
                <li class="list-group-item rounded-top">
                    <ul class="nav nav-pills nav-pills-hover flex-column">
                        <li class="nav-header d-flex text-primary pt-1 pb-2 font-weight-bold align-items-center">
                            <div>
                                Menu
                            </div>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="javascript:void(0);">
                                My Account
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="javascript:void(0);">
                                Profile settings
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="javascript:void(0);">
                                ออกจากระบบ
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </b-popover> -->
  </div>
</template>

<script>
import { capitalize } from "lodash";
import { Auth, User } from "../../models";
import CreateOrUpdateUser from "../modal/CreateOrUpdateUser";

export default {
  props: {
    user: {
      type: User,
    },
  },

  components: {
    CreateOrUpdateUser,
  },

  data() {
    return {
      showModal: false,
    };
  },

  computed: {
    checkRetireYear(){
      // console.log(this.user)
      const currentYear = new Date().getFullYear();
      // console.log(this.user.retire_year)
      // console.log(((this.user.retire_year - 543) - currentYear <= 1))
      if(((this.user.retire_year - 543) - currentYear <= 1)){
        return true;
      }else{
        return false;
      }
    },

    forceUpdateUserData() {
      const { bd_date, birth_date, id_card } = this.user;

      return !bd_date || !birth_date || !id_card;
    },

    name() {
      return this.user
        ? `${capitalize(this.user.first_name)} ${capitalize(
            this.user.last_name
          )}`
        : "";
    },

    userTotalTime() {
      return this.user.total_time ? Math.floor(this.user.total_time) : 0;
    },

    userMedal() {
      let medal;

      if (this.userTotalTime) {
        if (this.userTotalTime >= 200) {
          medal = "ทอง";
        } else if (this.userTotalTime >= 150) {
          medal = "เงิน";
        } else if (this.userTotalTime >= 100) {
          medal = "ทองแดง";
        }
      }

      return medal;
    },

    medalImage() {
      const { total_time = null } = this.user;

      let baseSrc = "medal-start.png";

      // if (medal) {
      //   if (medal === "ทองแดง") {
      //     baseSrc = "medal-bronze.png";
      //   }
      //   if (medal === "เงิน") {
      //     baseSrc = "medal-silver.png";
      //   }
      //   if (medal === "ทอง") {
      //     baseSrc = "medal-gold.png";
      //   }
      // }

      if (total_time) {
        if (total_time >= 100) {
          baseSrc = "medal-bronze.png";
        } else if (total_time >= 150) {
          baseSrc = "medal-silver.png";
        } else if (total_time >= 200) {
          baseSrc = "medal-gold.png";
        }
      }

      return baseSrc;
    },
  },

  mounted() {
    if (this.forceUpdateUserData) {
      this.showModal = true;
    }
  },

  methods: {
    showTooltip() {
      this.$refs.tooltipSuccess._toolpop
        .getTipElement()
        .classList.add("tooltip-success");
    },

    showPopoverIntegrations() {
      this.$refs.userBoxPopover._toolpop
        .getTipElement()
        .classList.add(
          "popover-custom-wrapper",
          "popover-custom-lg",
          "popover-secondary"
        );
    },

    onCreateSuccess() {
      Auth.load();
    },
  },
};
</script>

<style lang="scss">
.sidebar-collapsed {
  .user-box__name {
    opacity: 0;
  }
}

.user-box__name {
  opacity: 1;
  width: 200px;
  transition: opacity 0.3s ease;
}
</style>
