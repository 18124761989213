import { Model } from "@vuex-orm/core";

export class TeachingPlan extends Model {
    static get entity() {
        return "teaching_plans"
    }

    static fields() {
        return {
            id: this.attr(null),
            lesson_number: this.string(""),
            lesson_name: this.string(""),
            date: this.attr(null),
            time: this.string(""),
            content: this.string(""),
            note: this.string(""),
            create_by: this.string(""),
            update_by: this.string(""),
            createdAt: this.attr(null),
            updatedAt: this.attr(null),
            teachingDareId: this.attr(null),
            totalTime: this.attr(null),
            teaching_actual_imgs: this.attr([])
        }
    }

    static get apiConfig() {
        return {
            actions: {
                findAll(params = {}) {
                    return this.get('/teaching_plan', { params });
                }
            }
        };
    }
}