import { Model } from "@vuex-orm/core";

export class Announcement extends Model {
  static get entity() {
    return "announcement";
  }

  static fields() {
    return {
      id: this.attr(null),
      announcement_title: this.string(""),
      key_img: this.attr(null),
			is_active: this.boolean(false),
      create_by: this.attr(null),
      update_by: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
    };
  }

  static get apiConfig() {
    return {
      actions: {
        findAll(params = {}) {
          return this.get("/announcement", { params });
        },

        findOne(id) {
          return this.get(`/announcement/${id}`);
        },

        create(body) {
          return this.post("/announcement", body);
        },

        update(id, body) {
          return this.patch(`/announcement/${id}`, body);
        },

        destroy(id) {
          return this.delete(`/announcement/${id}`, { delete: id });
        },
      },
    };
  }
}
