import { Model } from "@vuex-orm/core";

export class Lesson extends Model {
    static get entity() {
        return "lessons"
    }

    static fields() {
        return {
            "id": this.attr(null),
            "lesson_no": this.attr(null),
            "lesson_title": this.string(""),
            "lesson_type": this.string(""),
            "lesson_watch_url": this.string(""),
            "lesson_url": this.string(""),
            "lesson_key": this.string(""),
            "lesson_order": this.attr(null),
            "lesson_cover_time": this.number(0),
            "create_by": this.attr(null),
            "update_by": this.attr(null),
            "createdAt": this.attr(null),
            "updatedAt": this.attr(null),
        }
    }

    async getDownloadUrl() {
        const promise = await Lesson.api().getDownloadUrl(this.id);

        const { url } = promise.response.data;

        return url;
    }

    static get apiConfig() {
        return {
            actions: {
                getDownloadUrl(id, params = {}) {
                    return this.get(`/lessons/${id}/download-url`, { params }, { save: false });
                },

                findAll(params = {}, props = {}) {
                    return this.get('/lessons', { params }, props);
                },

                create(body) {
                    return this.post('/lessons', body);
                },

                update(id, body) {
                    return this.put(`/lessons/${id}`, body);
                },

                destroy(id) {
                    return this.delete(`/lessons/${id}`, { delete: id });
                }
            }
        };
    }
}