<template>
  <b-form @submit.stop.prevent>
    <div class="d-flex flex-row flex-wrap">
      <div class="col-12 px-2">
        <label class="font-weight-bold ft-s-14" for="profileImg"
          >อัพโหลดรูปโปรไฟล์</label
        >

        <b-button
          size="sm"
          variant="primary"
          class="mx-2"
          :disabled="disabled"
          @click="toggleAttachFile()"
        >
          เลือกไฟล์
        </b-button>

        <small class="text-black-50 ml-2 font-weight-bold"
          >*JPG, PNG ขนาดไม่เกิน 10MB (ขนาดแนะนำ {{ width }} x
          {{ height }} px)</small
        >

        <input
          v-show="false"
          type="file"
          id="profileImg"
          name="profileImg"
          accept="image/*"
          ref="profileImg"
          @change="previewImage($event)"
        />
      </div>

      <div
        class="col-lg-12 col-12 px-2 mb-2"
        v-if="form.profile_image"
      >
        <b-spinner
          v-show="isProcessing"
          label="processing image..."
          class="ml-2 mb-4"
        >
        </b-spinner>
        <img
          class="d-block img-fluid w-50"
          :src="
            form.profile_image.url !== ''
              ? form.profile_image.url
              : form.profile_image.raw
          "
        />
      </div>

      <div class="col-lg-4 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="rank">ยศ</label>
        <v-select
          id="rank"
          v-model="rank"
          :options="ranks"
          :disabled="disabled"
          :loading="isFetchingRank"
        ></v-select>

        <small
          v-if="
            invalid &&
            validation.formTeacher.mRankId &&
            !validation.formTeacher.mRankId.required
          "
          class="text-danger"
        >
          กรุณาเลือกยศ
        </small>
      </div>

      <div class="col-lg-4 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="firstName">ชื่อ</label>

        <b-form-input
          id="firstName"
          :state="invalid ? validation.formTeacher.first_name.required : null"
          type="text"
          :disabled="disabled"
          v-model="form.first_name"
        ></b-form-input>

        <b-form-invalid-feedback id="firstName-feedback">
          กรุณากรอกชื่อ
        </b-form-invalid-feedback>
      </div>

      <div class="col-lg-4 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="lastName">นามสกุล</label>

        <b-form-input
          id="lastName"
          :state="invalid ? validation.formTeacher.last_name.required : null"
          type="text"
          :disabled="disabled"
          v-model="form.last_name"
        ></b-form-input>

        <b-form-invalid-feedback id="lastName-feedback">
          กรุณากรอกนามสกุล
        </b-form-invalid-feedback>
      </div>

      <div class="col-lg-4 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="positionName">ตำแหน่ง</label>
        <input
          id="positionName"
          class="form-control"
          type="text"
          :disabled="disabled"
          v-model="form.position_name"
        />
      </div>

      <div class="col-lg-4 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="birthDate">วัน/เดือน/ปีเกิด</label>
        <datepicker
          ref="datepicker"
          id="birthDate"
          input-class="form-control"
          required
          :language="th"
          :format="formatBuddhistBirthDate"
          v-model="birthDate"
        ></datepicker>

        <small
          v-if="
            invalid &&
            validation.formTeacher.birth_date &&
            !validation.formTeacher.birth_date.required
          "
          class="text-danger"
        >
          กรุณาเลือกวัน/เดือน/ปีเกิด
        </small>
      </div>

      <div class="col-lg-4 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="retireYear">ปีเกษียณ</label>

        <b-form-input
          id="retireYear"
          type="text"
          disabled
          placeholder="2xxx"
          v-mask="'####'"
          v-model="form.retire_year"
        ></b-form-input>
      </div>

      <div class="col-lg-4 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="idCard">เลขบัตรประชาชน</label>

        <b-form-input
          id="idCard"
          :state="
            invalid
              ? validation.formTeacher.id_card &&
                validation.formTeacher.id_card.required &&
                validation.formTeacher.id_card.valid
              : null
          "
          type="text"
          placeholder="x-xxxx-xxxxx-xx-x"
          :disabled="disabled"
          required
          v-mask="'#-####-#####-##-#'"
          v-model="form.id_card"
        ></b-form-input>

        <b-form-invalid-feedback id="idCard-feedback">
          <span
            v-if="
              validation.formTeacher.id_card &&
              !validation.formTeacher.id_card.required
            "
          >
            กรุณากรอกเลขบัตรประชาชน
          </span>

          <span
            v-else-if="
              validation.formTeacher.id_card &&
              !validation.formTeacher.id_card.valid
            "
          >
            เลขบัตรประชาชนไม่ถูกต้อง
          </span>
          <!-- <span>เลขบัตรประชาชนไม่ถูกต้อง</span> -->
        </b-form-invalid-feedback>
      </div>

      <div class="col-lg-4 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="phoneNumber">เบอร์โทรศัพท์</label>

        <b-form-input
          id="phoneNumber"
          :state="
            invalid
              ? validation.formTeacher.phone_number &&
                validation.formTeacher.phone_number.required &&
                validation.formTeacher.phone_number.minLength &&
                validation.formTeacher.phone_number.maxLength
              : null
          "
          type="tel"
          placeholder="0xx-xxx-xxxx"
          :disabled="disabled"
          v-mask="'0##-###-####'"
          v-model="form.phone_number"
        ></b-form-input>

        <b-form-invalid-feedback id="phoneNumber-feedback">
          <span
            v-if="
              validation.formTeacher.phone_number &&
              !validation.formTeacher.phone_number.required
            "
          >
            กรุณากรอกเบอร์โทรศัพท์
          </span>

          <span
            v-else-if="
              validation.formTeacher.phone_number &&
              (!validation.formTeacher.phone_number.minLength ||
                !validation.formTeacher.phone_number.maxLength)
            "
          >
            เบอร์โทรศัพท์ไม่ถูกต้อง
          </span>
        </b-form-invalid-feedback>
      </div>

      <div class="col-lg-4 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="email">อีเมล</label>

        <b-form-input
          id="email"
          :state="
            invalid
              ? validation.formTeacher.email &&
                validation.formTeacher.email.required &&
                validation.formTeacher.email.email
              : null
          "
          type="email"
          :disabled="disabled"
          v-model="form.email"
        ></b-form-input>

        <b-form-invalid-feedback id="phoneNumber-feedback">
          <span
            v-if="
              validation.formTeacher.email &&
              !validation.formTeacher.email.required
            "
          >
            กรุณากรอกอีเมล
          </span>

          <span
            v-else-if="
              validation.formTeacher.email &&
              !validation.formTeacher.email.email
            "
          >
            อีเมลไม่ถูกต้อง
          </span>
        </b-form-invalid-feedback>
      </div>

      <div
        v-if="authUser.isAdminType && !authUser.isExecutive"
        class="col-lg-2 col-12 px-2 mb-2"
      >
        <label class="font-weight-bold" for="userStatus">สถานะ</label>

        <v-select
          id="userStatus"
          v-model="form.user_status"
          :options="userStatuses"
          :disabled="disabled"
        ></v-select>

        <small
          v-if="
            invalid &&
            validation.formTeacher.user_status &&
            !validation.formTeacher.user_status.required
          "
          class="text-danger"
        >
          กรุณาเลือกสถานะ
        </small>
      </div>

      <div
        v-if="editData && editData.id && authUser.isSuperAdmin"
        class="col-lg-2 col-12 px-2 my-2 d-flex align-items-end"
      >
        <b-form-checkbox
          v-model="isAdmin"
          name="isAdmin"
          switch
          :disabled="disabled || disabledInput.isAdmin"
        >
          สิทธิ์ผู้ดูแลระบบ
        </b-form-checkbox>
      </div>

      <div v-if="isAdmin" class="col-lg-6 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="userStatus"
          >ประเภทสิทธิ์ผู้ดูแลระบบ</label
        >

        <v-select
          id="userStatus"
          v-model="adminType"
          :options="adminTypes"
          :disabled="!isAdmin || disabled || disabledInput.adminType"
        ></v-select>

        <small
          v-if="
            invalid &&
            validation.formTeacher.admin_type &&
            !validation.formTeacher.admin_type.required
          "
          class="text-danger"
        >
          กรุณาเลือกประเภทสิทธิ์ผู้ดูแลระบบ
        </small>
      </div>

      <div class="col-lg-12 col-12 px-2 my-2 d-flex align-items-center">
        <b-form-checkbox
          v-model="isAllow"
          name="isAllow"
          switch
          :disabled="disabled"
          @change="onAllow"
        >
          บันทึกข้อมูลให้ผู้อื่น
        </b-form-checkbox>
      </div>

      <div class="col-lg-6 col-12 px-2 mb-2" v-if="isAllow">
        <label class="font-weight-bold" for="userStatus"
          >ระบุหมายเลขโทรศัพท์</label
        >

        <b-form-input
          id="allowUserId"
          type="tel"
          :disabled="disabled || !isAllow"
          v-model="formLogin.tel"
        ></b-form-input>

        <!-- <v-select
          id="userPermission"
          v-model="allowUserId"
          :options="allUsers"
          :disabled="disabled || !isAllow"
        ></v-select> -->

        <!-- <small
          v-if="
            invalid &&
            validation.formTeacher.admin_type &&
            !validation.formTeacher.admin_type.required
          "
          class="text-danger"
        >
          กรุณาเลือกประเภทสิทธิ์ผู้ดูแลระบบ
        </small> -->
      </div>

      <div
        class="col-lg-3 col-12 px-2 mb-2 d-flex align-items-end"
        v-if="isAllow"
      >
        <b-button
          size="sm"
          variant="primary"
          class="mx-2"
          :disabled="disabled"
          @click="signIn"
        >
          ขอ OTP
        </b-button>
      </div>

      <div class="col-12 px-2 my-2">
        <label class="font-weight-bold" for="address"
          >ที่อยู่ปัจจุบันที่สามารถติดต่อได้</label
        >

        <b-form-textarea
          id="address"
          :state="null"
          :disabled="disabled"
          v-model="form.address"
          rows="3"
          max-rows="6"
        ></b-form-textarea>
      </div>

      <div class="col-12 px-2 my-2">
        <label class="font-weight-bold" for="addressDocument"
          >ที่อยู่สำหรับจัดส่งเอกสาร</label
        >

        <b-form-textarea
          id="addressDocument"
          :state="null"
          :disabled="disabled"
          v-model="form.address_document"
          rows="3"
          max-rows="6"
        ></b-form-textarea>
      </div>

      <!-- <div v-if="isClass" class="col-lg-2 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="box5"
          >จำนวนนักเรียนจริง</label
        >
        <input
          class="form-control custom__height"
          type="number"
          :min="0"
          :disabled="disabled"
          v-model.number="form.actual_student"
        />
      </div> -->
    </div>

    <b-modal
      v-model="showModalOTP"
      id="otp-modal"
      centered
      hide-footer
      hide-header
    >
      <!-- <div slot="modal-header">
                <h6 class="display-4 font-weight-bold">Search</h6>
                <p class="text-black-50 mb-0">
                  Use the form below to search for files
                </p>
              </div> -->
      <b-container>
        <b-row>
          <b-col>
            <h5 class="display-3 text-primary font-weight-bold">
              กรอกรหัส OTP
            </h5>

            <p class="text-black-50">
              รหัส OTP ได้ถูกส่งไปยังเบอร์โทรศัพท์หมายเลข {{ formLogin.tel }}
            </p>

            <form>
              <div class="form-group">
                <label for="otp_code">รหัส OTP</label>
                <input
                  type="text"
                  class="form-control"
                  name="otp_code"
                  v-model="formLogin.otp_code"
                />
              </div>

              <button
                class="btn btn-lg btn-primary btn-block"
                :disabled="checkingOTP"
                @click.prevent="validateOTP"
              >
                <b-spinner v-if="checkingOTP" label="Loading..."></b-spinner>
                <span v-else>ยืนยัน</span>
              </button>
            </form>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </b-form>
</template>

<script>
import {
  toBuddhistCalendar,
  formatBuddhistBirthDate,
  calculateRetireYear,
} from "../../helpers/datepicker-helper";
import formatDateMixin from "../../mixins/formatDateMixin";
import vSelect from "vue-select";
import { th } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
import { MRank, Auth, User } from "../../models";
import Compressor from "compressorjs";
// import CarouselBanner from "../carousel/BannerImage";

toBuddhistCalendar(Datepicker);

// const currentYear = new Date().getFullYear() + 543;

export default {
  mixins: [formatDateMixin],

  props: {
    formData: Object,
    editData: {
      type: Object,
      default: () => {},
    },
    isLesson: {
      type: Boolean,
      default: false,
    },
    isClass: {
      type: Boolean,
      default: false,
    },
    isTeacher: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    validation: {
      type: Object,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    disabledInput: {
      type: Object,
      default: () => ({}),
    },
    userType: {
      type: String,
      default: "user",
    },
    forceUpdate: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 1920,
    },

    height: {
      type: Number,
      default: 1080,
    },
  },

  model: {
    prop: "formData",
    event: "change",
  },

  components: {
    Datepicker,
    // CarouselBanner,
    "v-select": vSelect,
  },

  data() {
    return {
      userList: [],
      isProcessing: false,
      th,
      isFetchingRank: false,
      rank: null,
      adminType: null,
      isAllow: false,
      allowUserId: null,
      birthDate: this.editData.bd_date
        ? new Date(this.editData.bd_date).setFullYear(
            new Date(this.editData.bd_date).getFullYear() - 543
          )
        : null,
      form: {
        address: this.editData.address || "",
        address_document: this.editData.address_document || "",
        first_name: this.editData.first_name || "",
        last_name: this.editData.last_name || "",
        bd_date: this.editData.bd_date || "",
        birth_date: this.editData.birth_date || "",
        retire_year: this.editData.birth_date
          ? calculateRetireYear(this.editData.birth_date, true)
          : "",
        id_card: this.editData.id_card || "",
        phone_number: this.editData.phone_number || "",
        email: this.editData.email || null,
        mRankId: this.editData.mRankId || null,
        user_type: this.editData.user_type
          ? this.editData.user_type
          : this.userType
          ? this.userType
          : null,
        admin_type: this.editData.admin_type || null,
        user_status: this.editData.user_status || "เข้าสอน",
        position_name: this.editData.position_name || null,
        forceUpdate: this.forceUpdate,
        profile_image: this.editData.profile_image || null,
        allowUserId: this.editData.allowUserId || null,
      },
      formLogin: {
        tel: "",
        authToken: "",
        token: "",
        user_profiles: null,
        otp_code: "",
      },
      showModalOTP: false,
      checkingSignIn: false,
      checkingOTP: false,
      otpTimeout: 0,
      optInterval: null,
    };
  },

  watch: {
    otpTimeout: "onOtpTimeoutChanged",
    form: {
      deep: true,
      immediate: true,
      handler: "syncData",
    },

    adminType: {
      handler: "onAdminTypeChanged",
    },

    rank: {
      handler: "onRankChanged",
    },

    birthDate: {
      handler: "onBirthDateChanged",
    },
  },

  computed: {
    authUser() {
      return Auth.user();
    },

    userStatuses() {
      return ["เข้าสอน", "ไม่เข้าสอน", "เกษียณ", "เสียชีวิต", "ลาออก"];
    },

    adminTypes() {
      let adminTypes = [];

      if (this.authUser.isSuperAdmin || this.authUser.isExecutive) {
        adminTypes.push({ id: "EXECUTIVE", label: "ผู้บริหาร" });
      }

      return [
        ...adminTypes,
        { id: "ADMIN_ALL", label: "ทั่วประเทศ" },
        { id: "ADMIN_HEADQUARTER", label: "ระดับภาค" },
        { id: "ADMIN_DIVISION", label: "ระดับจังหวัด" },
        { id: "ADMIN_STATION", label: "ระดับสถานี" },
      ];
    },

    ranks() {
      return MRank.all().map((record) => ({
        id: record.id,
        label: record.m_rank_name,
      }));
    },

    allUsers() {
      return User.all().map((record) => ({
        id: record.id,
        label: `${record.first_name} ${record.last_name}`,
      }));
    },

    isAdmin: {
      get() {
        return (
          this.form.user_type === "ADMIN" ||
          this.form.user_type === "SUPER_ADMIN"
        );
      },

      set(v) {
        this.$set(this.form, "user_type", v ? "ADMIN" : "user");

        return this;
      },
    },

    // config here
    // profileImage: {
      
    // }
  },

  methods: {
    formatBuddhistBirthDate,
    toggleAttachFile() {
      this.$nextTick(() => {
        this.$refs.profileImg.click();
      });
    },
    async previewImage(event) {
      const self = this;
      const { width, height } = this;

      var input = event.target;

      const maxAllowedSize = 10 * 1024 * 1024;

      this.isProcessing = true;

      Array.from(input.files).forEach((file) => {
        if (file.size <= maxAllowedSize) {
          new Compressor(file, {
            quality: 0.8,

            maxWidth: width,

            maxHeight: height,

            // The compression process is asynchronous,
            // which means you have to access the `result` in the `success` hook function.
            success(result) {
              var reader = new FileReader();

              reader.onload = async (e) => {
                // self.$set(self.form, "file", result);
                // self.$set(self.form, "raw", e.target.result);

                // self.form.keyImg = e.target.result;

                // console.log(result);
                // console.log(e.target.result)

                self.$set(self.form, "profile_image", {
                  key: "",
                  url: "",
                  file: result,
                  raw: e.target.result,
                });

                self.isProcessing = false;
              };
              
              reader.readAsDataURL(result);
            },
            error(err) {
              console.log(err.message);
              this.$toast.error("ไม่สามารถอัพโหลดไฟล์ได้ กรุณาลองใหม่อีกครั้ง");
            },
          });

          // var reader = new FileReader();

          // reader.onload = async (e) => {
          //   this.$set(this.form, "file", file);
          //   this.$set(this.form, "raw", e.target.result);
          // };

          // reader.readAsDataURL(file);
        } else {
          this.$toast.error(
            "ไม่สามารถอัพโหลดไฟล์ขนาดใหญ่เกิน 10 MB ได้ กรุณาลองใหม่อีกครั้ง"
          );
        }
      });

      input.value = "";
    },

    async init() {
      this.isFetchingRank = true;

      try {
        await MRank.api().findAll();

        if (this.editData) {
          const { admin_type, mRankId } = this.editData;

          const mRank = MRank.find(mRankId);

          if (mRank) {
            this.rank = {
              id: mRank.id,
              label: mRank.m_rank_name,
            };
          }

          if (admin_type) {
            const targetAdminType = this.adminTypes.find(
              ({ id }) => admin_type == id
            );

            if (targetAdminType) {
              this.adminType = { ...targetAdminType };
            }
          }
        }
      } catch (error) {
        console.error(error);
        this.$toast.error("ไม่สามารถดึงข้อมูลยศได้ กรุณาลองใหม่อีกครั้ง");
      } finally {
        this.isFetchingRank = false;
      }
    },

    onAdminTypeChanged(adminType = null) {
      let id = null;

      if (adminType && adminType.id) {
        id = adminType.id;
      }

      this.$set(this.form, "admin_type", id);
    },

    onRankChanged(rank = null) {
      let id = null;

      if (rank && rank.id) {
        id = rank.id;
      }

      this.$set(this.form, "mRankId", id);
    },

    onBirthDateChanged(date) {
      const dObj = new Date(date.getTime());

      const buddhistBirthDate = formatBuddhistBirthDate(dObj);

      this.$set(this.form, "birth_date", buddhistBirthDate);

      dObj.setFullYear(dObj.getFullYear() + 543);
      dObj.setHours(0);
      dObj.setMinutes(0);
      dObj.setSeconds(0);

      this.$set(this.form, "retire_year", calculateRetireYear(dObj));
      this.$set(this.form, "bd_date", dObj.toISOString());
    },

    syncData(v) {
      this.$emit("change", v);
    },

    async onAllow() {
      // console.log(this.isAllow)
      // if(!this.allUsers.length > 100){
      //   await User.api().findAll();
      // }
    },

    async signIn() {
      this.checkingSignIn = true;

      let { tel } = this.formLogin;

      tel = tel.replace(/-/g, "");

      try {
        const { user_profiles, token: authToken } = await Auth.api().signIn(
          tel
        );

        this.$set(this.formLogin, "authToken", authToken);
        this.$set(this.formLogin, "user_profiles", user_profiles);

        const token = await Auth.api().getOTP(tel);

        this.$set(this.formLogin, "token", token);

        this.setOtpTimeout();

        this.showModalOTP = true;
      } catch (message) {
        this.$toast.error(message);
      } finally {
        this.checkingSignIn = false;
      }
    },

    setOtpTimeout() {
      this.otpTimeout = 30;
    },

    onOtpTimeoutChanged(v) {
      if (v > 0 && !this.optInterval) {
        this.optInterval = setInterval(() => {
          this.otpTimeout -= 1;
        }, 1000);
      } else {
        if (v === 0) {
          clearInterval(this.optInterval);
          this.optInterval = null;
        }
      }
    },

    async validateOTP() {
      this.checkingOTP = true;
      try {
        if (!this.formLogin.otp_code.length) {
          throw "กรุณากรอกรหัส OTP";
        }

        const valid = await Auth.api().validateOTP(
          this.formLogin.token,
          this.formLogin.otp_code
        );

        if (valid) {
          Auth.setAuthToken(this.formLogin.authToken);
          Auth.setAuthId(this.formLogin.user_profiles.id);

          this.showModalOTP = false;

          this.$toast.success(
            "เข้าสู่ระบบสำเร็จ ระบบจะเข้าสู้หน้าหลักในอีกสักครู่"
          );

          setTimeout(() => {
            location.href =
              decodeURIComponent(this.$route.query.redirectUrl) || "/";
          }, 1000);
        }
      } catch (message) {
        // console.log(message);
        this.$toast.error(message);
      } finally {
        this.checkingOTP = false;
      }
    },
  },

  mounted() {
    this.init();
  },
};
</script>
