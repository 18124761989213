<template>
  <div class="app-sidebar app-sidebar--dark" :class="sidebarbg">
    <div class="app-sidebar--header ml-4" style="height: 130px !important;">
      <user-box v-if="authUser" :user="authUser" />
    </div>

    <VuePerfectScrollbar class="app-sidebar--content">
      <div class="sidebar-navigation">
        <sidebar-menu showOneChild :menu="menu" @item-click="onItemClick" />
      </div>
    </VuePerfectScrollbar>

    <!-- <div
      class="bg-light toggle-sidebar-desktop pl-3 pt-2"
      style="height: 175px"
    >
      <b-button
        variant="link"
        target="_blank"
        class="toggle-sidebar text-left"
        href="https://e-learning.xn--l3cgao1bcb5b5d8hf8b6f.com"
        >E-Learning D.A.R.E. ประเทศไทย</b-button
      >

      <b-button
        variant="link"
        target="_blank"
        class="toggle-sidebar text-left"
        :href="`https://e-learning-admin.xn--l3cgao1bcb5b5d8hf8b6f.com?userId=${authUser.id}`"
        >ระบบจัดการ E-Learning D.A.R.E. ประเทศไทย</b-button
      >
    </div> -->

    <div class="bg-light toggle-sidebar-mobile pl-3" style="height: 140px">
      <!-- <router-link v-if="authUser" active-class="active" to="/registers" exact>
        <b-button
          variant="link"
          target="_blank"
          class="toggle-sidebar text-left"
          >สมัครสมาชิกชมรม D.A.R.E. ประเทศไทย</b-button
        >
      </router-link> -->

      <!-- <b-button
        variant="link"
        target="_blank"
        class="toggle-sidebar text-left"
        href="https://e-learning.xn--l3cgao1bcb5b5d8hf8b6f.com"
        >E-Learning D.A.R.E. ประเทศไทย</b-button
      >

      <b-button
        variant="link"
        target="_blank"
        class="toggle-sidebar text-left"
        :href="`https://e-learning-admin.xn--l3cgao1bcb5b5d8hf8b6f.com?userId=${authUser.id}`"
        >ระบบจัดการ E-Learning D.A.R.E. ประเทศไทย</b-button
      > -->
      <!-- <router-link
        v-if="authUser"
        class="text-left"
        variant="link"
        target="_blank"
        href="http://line.me/ti/p/@858xnebk"
        exact
      >
        แจ้งปัญหาการใช้งาน
      </router-link> -->

      <b-button
        v-if="authUser"
        variant="link"
        target="_blank"
        class="text-left"
        href="https://drive.google.com/file/d/1dN0fQpS5XgoTFVNIP-9_lswylqEut-4Z/view"
        >คู่มือการใช้งานระบบ</b-button
      >

      <router-link
        v-if="authUser"
        class="d-block ml-4 py-2"
        active-class="active"
        to="/tutorials"
        exact
      >
        วีดีโอสอนการใช้งานระบบ
      </router-link>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { SidebarMenu } from "vue-sidebar-menu";
import { Auth, TeachingDare, Survey } from "../../models";
import UserBox from "../sidebar/UserBox";

export default {
  components: {
    SidebarMenu,
    VuePerfectScrollbar,
    UserBox,
  },

  props: {
    sidebarbg: String,
  },

  data() {
    return {
      authUser: null,
      // menu: [
      //   {
      //     title: "หน้าหลัก",
      //     icon: {
      //       element: "home-icon",
      //     },
      //     href: "/",
      //     //   // child: [
      //     //   //     {
      //     //   //         href: '/',
      //     //   //         title: 'Web analytics',
      //     //   //     },
      //     //   //     {
      //     //   //         href: '/dashboards/monitoring',
      //     //   //         title: 'Monitoring',
      //     //   //         class: 'pr-2',
      //     //   //         badge: {
      //     //   //             text: 'New',
      //     //   //             class: 'badge badge-first ml-auto order-2'
      //     //   //         }
      //     //   //     },
      //     //   // ]
      //   },
      //   {
      //     title: "ข้อมูลแผนการสอน",
      //     icon: {
      //       element: "book-icon",
      //     },
      //     href: "/lesson-plans",
      //   },
      //   {
      //     title: "ข้อมูลการสอน",
      //     icon: {
      //       element: "clipboard-icon",
      //     },
      //     href: "/classes",
      //   },
      // ],
      collapsed: false,
    };
  },

  computed: {
    // authUser() {
    //   return Auth.user();
    // },

    menu() {
      const authUser = Auth.user();
      const surveyMenu = [];

      const surveys = Survey.all();

      if (surveys.length) {
        surveys.forEach((survey) => {
          surveyMenu.push({
            href: `/report/survey/${survey.id}`,
            title: survey.survey_title,
            class: "side-bar__menu-survey",
          });
        });
      }

      const baseMenu = [
        {
          title: "หน้าหลัก",
          icon: {
            element: "home-icon",
          },
          href: "/",
        },
        {
          title: "ภาพรวมการสอน",
          icon: {
            element: "pie-chart-icon",
          },
          href: "/dashboard",
          hidden: !authUser.isAdminType,
        },
        {
          title: "ข้อมูลแผนการสอน",
          icon: {
            element: "book-icon",
          },
          href: "/lesson-plans",
          hidden: authUser.isExecutive,
        },
        {
          title: "ข้อมูลการสอน",
          icon: {
            element: "clipboard-icon",
          },
          href: "/classes",
        },
        {
          href: "/transactions",
          title: "รายงานขอเบิกค่าตอบแทนการสอน",
          icon: {
            element: "file-text-icon",
          },
        },
        {
          href: "/certificate",
          title: "พิมพ์หนังสือรับรอง",
          icon: {
            element: "file-text-icon",
          },
        },
        {
          title: "จัดการข้อมูล",
          icon: {
            element: "database-icon",
          },
          hidden: !authUser.isAdminType || authUser.isExecutive,
          child: [
            {
              href: "/master-data/schools",
              title: "จัดการโรงเรียน",
              hidden: !authUser.isAdminType,
            },
            {
              href: "/master-data/administrators",
              title: "จัดการผู้ดูแลระบบ",
              hidden: !authUser.isAdminType,
            },
            {
              href: "/master-data/teachers",
              title: "จัดการครูตำรวจ D.A.R.E.",
              hidden: !authUser.isAdminType,
            },
            {
              href: "/master-data/students",
              title: "จัดการนักเรียน",
              hidden: !authUser.isAdminType,
            },
            {
              href: "/master-data/student-old",
              title: "ข้อมูลนักเรียนเก่า",
              hidden: !authUser.isAdminType,
            },
            {
              href: "/master-data/surveys",
              title: "จัดการแบบประเมิน",
              hidden: !authUser.isSuperAdmin,
            },
            {
              href: "/master-data/registrations",
              title: "จัดการสมาชิกชมรม",
              hidden: !authUser.isSuperAdmin && !authUser.isAdminAll,
            },
            {
              href: "/master-data/lessons",
              title: "จัดการเอกสารประกอบการสอน",
              hidden: !authUser.isSuperAdmin && !authUser.isAdminAll,
            },
            {
              href: "/master-data/tutorials",
              title: "จัดการวีดีโอสอนการใช้งานระบบ",
              hidden: !authUser.isSuperAdmin && !authUser.isAdminAll,
            },
          ],
        },
        {
          title: "รายงาน",
          icon: {
            element: "book-open-icon",
          },
          hidden: !authUser.isAdminType,
          child: [
            {
              title: "รายงานผลการประเมิน",
              // child: [
              //   {
              //     href: "/report/survey-teacher-dare",
              //     title: "แบบประเมินการเข้าสอนของครูตำรวจ",
              //   },
              //   {
              //     href: "/report/survey-student",
              //     title: "แบบประเมินผล(นักเรียน)",
              //   },
              //   {
              //     href: "/report/survey-teacher",
              //     title: "แบบประเมินผล(ครูประจำชั้น)",
              //   },
              //   {
              //     href: "/report/survey-parent",
              //     title: "แบบประเมินผล(ผู้ปกครอง)",
              //   },
              // ],
              child: surveyMenu,
              class: "side-bar__survey-sub-menu",
            },
            {
              href: "/report/overview",
              title: "รายงานภาพรวมแผนการสอน",
            },
            {
              href: "/report/progress",
              title: "รายงานสถานะแผนการสอน",
            },
            {
              href: "/report/transactions",
              title: "รายงานสรุปค่าสอนครู",
            },
            // {
            //   href: "/report/certificate",
            //   title: "การออกใบประกาศหลังเรียนจบ",
            // },
          ],
        },
        {
          title: "เอกสารประกอบการสอน",
          icon: {
            element: "book-open-icon",
          },
          href: "/lessons",
        },
        // {
        //   title: "สมัครสมาชิกชมรม D.A.R.E. ประเทศไทย",
        //   icon: {
        //     element: "file-plus-icon",
        //   },
        //   href: "/registers",
        // },
        // {
        //   title: "E-Learning D.A.R.E. ประเทศไทย",
        //   external: true,
        //   icon: {
        //     element: "airplay-icon",
        //   },
        //   class: "side-bar__menu-non-active",
        //   href: "https://e-learning.xn--l3cgao1bcb5b5d8hf8b6f.com",
        // },
        // {
        //   title: "ระบบจัดการ E-Learning D.A.R.E. ประเทศไทย",
        //   external: true,
        //   icon: {
        //     element: "server-icon",
        //   },
        //   class: "side-bar__menu-non-active",
        //   href: `https://e-learning-admin.xn--l3cgao1bcb5b5d8hf8b6f.com?userId=${authUser.id}`,
        // },
        {
          title: "ตั้งค่า",
          icon: {
            element: "setting-icon",
          },
          hidden: !authUser.isSuperAdmin,
          child: [
            {
              href: "/setting/announcement",
              title: "ตั้งค่าประกาศ",
              hidden: !authUser.isSuperAdmin,
            },
            {
              href: "/setting/config-m-class",
              title: "ตั้งค่าชั้นเรียน",
              hidden: !authUser.isSuperAdmin,
            },
            {
              href: "/setting/config-class",
              title: "ตั้งค่าแผนการสอน",
              hidden: !authUser.isSuperAdmin,
            },
            {
              href: "/setting/config-quota",
              title: "ตั้งค่าจำนวนโควต้าแผนการสอน",
              hidden: !authUser.isSuperAdmin && !authUser.isAdminAll,
            },
            {
              href: "/setting/banner-news",
              title: "จัดการข่าวประชาสัมพันธ์",
              hidden: !authUser.isSuperAdmin,
            },
            {
              href: "/setting/headquarters",
              title: "จัดการโครงสร้างภาค",
              hidden: !authUser.isSuperAdmin,
            },
            {
              href: "/setting/divisions",
              title: "จัดการโครงสร้างจังหวัด",
              hidden: !authUser.isSuperAdmin,
            },
            {
              href: "/setting/stations",
              title: "จัดการโครงสร้างสถานี",
              hidden: !authUser.isSuperAdmin,
            },
          ],
        },
      ];

      // let additionalMenu = [];

      // if (authUser && authUser.isAdminType) {
      //   let child = [];

      //   child.push({
      //     href: "/master-data/schools",
      //     title: "การจัดการโรงเรียน",
      //     hidden: !authUser.isSuperAdmin,
      //   });

      //   if (authUser.isSuperAdmin) {
      //     child.push({
      //       href: "/master-data/administrators",
      //       title: "การจัดการผู้ดูแลระบบ",
      //     });
      //   }

      //   child.push({
      //     href: "/master-data/teachers",
      //     title: "การจัดการคุณครู",
      //   });

      //   child.push({
      //     href: "/master-data/students",
      //     title: "การจัดการนักเรียน",
      //   });
      //   // if (authUser.isSuperAdmin) {
      //   // }

      //   additionalMenu = [
      //     ...additionalMenu,
      //     {
      //       title: "ข้อมูลหลัก",
      //       icon: {
      //         element: "database-icon",
      //       },
      //       child,
      //     },
      //   ];
      // }

      return baseMenu;
    },

    sidebarCollapsed: {
      get() {
        return this.$store.state.sidebarCollapsed;
      },
      set(value) {
        this.$store.commit("SET_SIDEBAR_COLLAPSED", value);
      },
    },

    sidebarCollapsedMobile: {
      get() {
        return this.$store.state.sidebarCollapsedMobile;
      },
      set(value) {
        this.$store.commit("SET_SIDEBAR_COLLAPSED_MOBILE", value);
      },
    },
  },

  methods: {
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },

    onItemClick() {
      if (this.sidebarCollapsedMobile) {
        this.sidebarCollapsedMobile = false;
      }
    },

    async fetch() {
      await Survey.api().findAll();

      const { profile_image = null, ...rest } = Auth.user();

      let img_url

      if (profile_image) {
          let imgPromise = await TeachingDare.api().getImgUrl({
              key: profile_image,
          });

          if (imgPromise && imgPromise.response) {
              const { url } = await imgPromise.response.data; // url, status

              if (url) {
                  img_url = url
              }
          }
      }

      this.authUser = {
        ...rest,
        profile_image: {
          key: profile_image,
          url: img_url
        },
      }
    },
  },

  async mounted() {
    await this.fetch();
  },
};
</script>
