import Vue from "vue";
import { ClipboardIcon, DatabaseIcon, HomeIcon, BookOpenIcon, BookIcon, PieChartIcon, FileTextIcon, ServerIcon, AirplayIcon, FilePlusIcon, SettingsIcon } from "vue-feather-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faAngleDown,
  faAngleUp,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faArrowLeft,
  faEye,
  faCog,
  faTimes,
  faArrowsAltV,
  faCheckCircle,
  faTimesCircle,
  faComments,
  faUser,
  faEdit,
  faCircle,
  faCheck,
  faBars,
  faLifeRing,
  faSignOutAlt,
  faArrowsAltH,
  faWindowClose,
  faSync,
  faStar,
  faFileAlt,
  faFileDownload,
  faPlayCircle,
  faQuestionCircle,
  faInfoCircle,
  faBookOpen,
  faChevronRight,
  faChevronDown,
  faExclamationTriangle,
  faPlus,
  faCogs,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";

library.add([
  faAngleDown,
  faAngleUp,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faArrowLeft,
  faEye,
  faCog,
  faTimes,
  faArrowsAltV,
  faCheckCircle,
  faTimesCircle,
  faComments,
  faUser,
  faEdit,
  faCircle,
  faCheck,
  faBars,
  faLifeRing,
  faSignOutAlt,
  faArrowsAltH,
  faWindowClose,
  faSync,
  faStar,
  faFileAlt,
  faFileDownload,
  faPlayCircle,
  faQuestionCircle,
  faInfoCircle,
  faBookOpen,
  faChevronRight,
  faChevronDown,
  faExclamationTriangle,
  faPlus,
  faCogs,
  faChevronLeft,
]);

Vue.component('clipboard-icon', ClipboardIcon);
Vue.component('database-icon', DatabaseIcon);
Vue.component('home-icon', HomeIcon);
Vue.component('book-icon', BookIcon);
Vue.component('pie-chart-icon', PieChartIcon);
Vue.component('book-open-icon', BookOpenIcon);
Vue.component('file-text-icon', FileTextIcon);
Vue.component('server-icon', ServerIcon);
Vue.component('airplay-icon', AirplayIcon);
Vue.component('file-plus-icon', FilePlusIcon);
Vue.component('setting-icon', SettingsIcon);